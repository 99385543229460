







export default {
  name: 'TestComponent',
  methods: {
    typedMethod(something: string) {
      return something;
    }
  }
};














































import { ColorFacet, ColorFacetValue, FacetAware, FacetValue, TemplateOverridable } from '../../../shared/interfaces';
import { computed, defineComponent, nextTick, onMounted } from '@vue/composition-api';
import UrlBuilder from '../../../shared/UrlBuilder';
import { SVGInjector } from '@tanem/svg-injector';

interface ItemFilterImageProps extends TemplateOverridable, FacetAware {
  facet: ColorFacet;
  fallbackImage: string;
}

export default defineComponent({
  name: 'ItemFilterImage',
  props: {
    facet: {
      type: Object,
      required: true
    },
    fallbackImage: {
      type: String,
      default: ''
    }
  },
  setup: (props: ItemFilterImageProps, { root }) => {
    const handleImageError = (event: Event, colorValue: ColorFacetValue): void => {
      const target = event.target as HTMLImageElement;

      if (!colorValue.hexValue) {
        target.src = props.fallbackImage;
      } else {
        target.remove();
      }
    };

    const isLoading = computed(() => root.$store.state.isLoading);

    const updateFacet = (facetValue: FacetValue): void => {
      UrlBuilder.updateSelectedFilters(props.facet, props.facet.id, facetValue.name);
    };

    const injectSvgImages = async () => {
      await nextTick();
      SVGInjector(document.getElementsByClassName('fl-svg'));
    };

    onMounted(injectSvgImages);

    return {
      handleImageError,
      isLoading,
      updateFacet
    };
  }
});

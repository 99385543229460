








































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import { TemplateOverridable } from '../../shared/interfaces';
import UrlBuilder from '../../shared/UrlBuilder';
import * as jQuery from 'jquery';

interface ItemSearchProps extends TemplateOverridable {
  showItemImages: boolean;
  forwardToSingleItem: boolean;
}

export default defineComponent({
  name: 'FindologicItemSearch',
  props: {
    template: {
      type: String,
      default: '#vue-item-search'
    },
    showItemImages: {
      type: Boolean,
      default: false
    },
    forwardToSingleItem: {
      type: Boolean,
      default: false
    }
  },
  setup: (props: ItemSearchProps, { root }) => {
    root.$options.template = props.template;

    const promiseCount = ref(0);
    const autocompleteResult = ref([]);
    const selectedAutocompleteIndex = ref(-1);
    const isSearchFocused = ref(false);
    const searchInput = ref('');

    const selectedAutocompleteItem = computed(() => null);

    const search = () => {
      const searchBox = $('#searchBox') as unknown as jQuery.Accordion;

      searchBox.collapse('hide');
    };

    // Implement methods required by the Ceres template.
    const autocomplete = () => null;
    const selectAutocompleteItem = () => null;
    const keyup = () => null;
    const keydown = () => null;

    onMounted(() => {
      root.$nextTick(() => {
        const urlParams = UrlBuilder.getUrlParams(document.location.search);

        root.$store.commit('setItemListSearchString', urlParams.query);

        const rawQuery = urlParams.query ? urlParams.query as string : '';

        // Manually regex out all "+" signs as decodeURIComponent does not take care of that.
        // If we wouldn't replace them with spaces, "+" signs would be displayed in the search field.
        searchInput.value = decodeURIComponent(rawQuery.replace(/\+/g, ' '));
      });
    });

    root.$props.forwardToSingleItem = window.App.config.search.forwardToSingleItem;

    return {
      promiseCount,
      autocompleteResult,
      selectedAutocompleteIndex,
      isSearchFocused,
      searchInput,
      selectedAutocompleteItem,
      search,
      autocomplete,
      selectAutocompleteItem,
      keyup,
      keydown,
    };
  }
});

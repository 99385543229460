










































































import { defineComponent } from '@vue/composition-api';
import { FacetAware, TemplateOverridable } from '../../../shared/interfaces';
import BaseDropdown from '../../../mixins/baseDropdown';

interface ItemDropdownProps extends TemplateOverridable, FacetAware { }

export default defineComponent({
  name: 'ItemDropdown',
  mixins: [
      BaseDropdown
  ],
  setup: (props: ItemDropdownProps, { root }) => {
    root.$options.template = props.template || '#vue-item-dropdown';

    const trans = (key: string) => {
      return window.ceresTranslate(key);
    };

    return { trans };
  }
});
